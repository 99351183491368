<template>
  <div>
    <header-slot />
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: $route.meta.inProccessTab }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="$route.matched[2].name == $route.meta.inProccessTab"
      >
        IN PROCCESS
      </b-nav-item>
      <b-nav-item
        :to="{ name: $route.meta.sentTab }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="$route.matched[2].name == $route.meta.sentTab"
      >
        SENT
      </b-nav-item>
    </b-nav>
    <b-card class="border-top-primary border-3 border-table-radius px-0">
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>

export default {
  name: "SendingCreditors",
};
</script>

